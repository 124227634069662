body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* div.welcome {
  background:
} */

.section1 {
  padding-top: 3%;
  padding-bottom: 3%;
  background: #dcf5eb;
}

.header {
  padding-bottom: 3%;
  margin-top: 3%
}

.card{
  height: 100%
}

.welcome {
  /* padding-top: 2%;
  padding-bottom: 2%; */
  height: 75vh;
  vertical-align: middle;
}

.section {
  padding-top: 3%;
  padding-bottom: 3%
}



.external-links {
  padding-top: 2%;
}

.welcome-content {
  padding-top: 23vh;
}

.welcome-logo {
  max-height: 10vh;
  padding-right: 8%;
  padding-left: 8%
}

.welcome .row {
  justify-content: center
}

div.welcome .container {
  height: 75vh;
  background: #0080ffe3;
  color: white;
  align-items: center;
  font-weight: 700;
}

.info {
  font-weight: 700;
  font-style: italic;
}

.project-title {
  color: #0080ffe3;
}

.profile {
  padding-left: 1%;
  width: 25%
}

.profile-text {
  /* width: 100%; */
  padding-left: 15%;
  border: black;
}

.prof-text {
  margin-top: 30%;
}


.profile-image{
  max-width: 100%;
  border-radius: 100%


}

div.welcome .container .welcome-content div.row .h1,
div.welcome .container .welcome-content div.row .h3 {
  color: white;
  font-weight: 700;
}
